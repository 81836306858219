body,html {
  font-family: sans-serif;
  margin:0px;
  height:100%;
  width:100%;
  scroll-behavior: smooth;
}

.MuiLinearProgress-barColorSecondary{
    background-color: #b99e7d !important;
}