.slick-active button {
  background-color: black !important;
}
.menuBtnTrans-enter {
  width: 50px !important;
  margin-left: 0%;
  transform: rotateY(180deg);
  transform-origin: left;
  transition: transform 125ms;
}
.menuBtnTrans-enter-active {
  z-index: -1 !important;
  opacity: 0 !important;
}
.menuBtnTrans-enter-done {
  width: 50px !important;
  margin-left: 50%;
  transform: rotateY(0deg);
  transform-origin: left;
  animation: myani 125ms;
  transition: transform 125ms;
}
.socialmediaandothers-exit {
  opacity: 1;
}
.socialmediaandothers-exit-active {
  opacity: 0.5;
}
.socialmediaandothers-exit-done {
  opacity: 0;
  height: 0px !important;
}
.socialmediaandothers-enter {
  height: 50%;
  opacity: 0;
}
.socialmediaandothers-enter-active {
  height: 80%;
  opacity: 0.8;
}
.socialmediaandothers-enter-done {
  height: 100%;
  opacity: 1;
}
@keyframes myani {
  from {
    z-index: 0;
  }
  to {
    z-index: 1203;
  }
}

.menuBtnTrans-exit {
  width: 50px !important;
  margin-left: 50%;
  transform: rotateY(180deg);
  transform-origin: left;
  transition: transform 250ms;
  z-index: -1 !important;
}
.menuBtnTrans-exit-active {
  opacity: 0;
  margin-left: 45%;
  transform: rotateY(90deg);
  transform-origin: inherit;
  transition: all 250ms;
  z-index: -1 !important;
}
.menuBtnTrans-exit-done {
  width: 50px !important;
  margin-left: 0%;
  transform: rotateY(0deg);
  transform-origin: left;
  transition: transform 250ms;
}

.ProgressBarTrans-enter {
  margin-right: 0%;
}
.ProgressBarTrans-enter-active {
  right: 350px !important;
  transition: all ease-in-out 400ms;
}
.ProgressBarTrans-exit {
  margin-right: 350px;
}
.ProgressBarTrans-exit-active {
  margin-right: 0%;
  transition: all ease-in-out 400ms;
}
.ProgressBarTrans-enter-done {
  margin-right: 350px;
}

.alert-enter-done {
  height: 100%;
}
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}

.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-hinge-down:hover > img {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}

.imghvr-hinge-down:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

[class*=" imghvr-hinge-"]:hover img,
[class^="imghvr-hinge-"]:hover img {
  opacity: 0;
}

.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

[class*=" imghvr-hinge-"] figcaption,
[class^="imghvr-hinge-"] figcaption {
  opacity: 0;
  z-index: 1;
}

[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #135796;
  padding: 30 px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}

.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  -moz-transform-origin: 50% -50%;
  -ms-transform-origin: 50% -50%;
  -o-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}

[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #135796;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}

[class*=" imghvr-"],
[class*=" imghvr-"] *,
[class*=" imghvr-"]:after,
[class*=" imghvr-"] :after,
[class*=" imghvr-"]:before,
[class*=" imghvr-"] :before,
[class^="imghvr-"],
[class^="imghvr-"] *,
[class^="imghvr-"]:after,
[class^="imghvr-"] :after,
[class^="imghvr-"]:before,
[class^="imghvr-"] :before {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

[class*=" imghvr-"],
[class^="imghvr-"] {
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  background-color: #2266a5;
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[class*=" imghvr-hinge-"]:hover figcaption,
[class^="imghvr-hinge-"]:hover figcaption {
  opacity: 1;
  transition-delay: 0.21s;
}
