@font-face {
    font-family: 'GothicRegular';
    src: url(/static/media/CenturyGothicRegular.09f6b2ba.ttf) format('truetype');
   } /* //Subtitle 2 */
   
@font-face {
    font-family: 'AmandaSignature';
    src: url(/static/media/amandasignature.8e0f01c0.ttf) format('truetype');
} /* //by font*/

@font-face {
    font-family: 'Brigham';
    src: url(/static/media/Brigham.8d4fb3e4.otf) format('truetype');
}/* //signeture font */

@font-face {
    font-family: 'CenturyGothicBold';
    src: url(/static/media/CenturyGothicBold.bc420c1c.ttf) format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSansSemibold';
    src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSansBold';
    src: url(/static/media/OpenSans-Bold.50145685.ttf) format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSans-Semibold_0';
    src: url(/static/media/OpenSans-Semibold_0.33f225b8.ttf) format('truetype');
}/* //Success Stories */


body,html {
  font-family: sans-serif;
  margin:0px;
  height:100%;
  width:100%;
  scroll-behavior: smooth;
}

.MuiLinearProgress-barColorSecondary{
    background-color: #b99e7d !important;
}
.slick-active button {
  background-color: black !important;
}
.menuBtnTrans-enter {
  width: 50px !important;
  margin-left: 0%;
  -webkit-transform: rotateY(180deg);
     -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
      -ms-transform-origin: left;
       -o-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 125ms;
  transition: -webkit-transform 125ms;
  -o-transition: -o-transform 125ms;
  -moz-transition: transform 125ms, -moz-transform 125ms;
  transition: transform 125ms;
  transition: transform 125ms, -webkit-transform 125ms, -moz-transform 125ms, -o-transform 125ms;
}
.menuBtnTrans-enter-active {
  z-index: -1 !important;
  opacity: 0 !important;
}
.menuBtnTrans-enter-done {
  width: 50px !important;
  margin-left: 50%;
  -webkit-transform: rotateY(0deg);
     -moz-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
      -ms-transform-origin: left;
       -o-transform-origin: left;
          transform-origin: left;
  -webkit-animation: myani 125ms;
     -moz-animation: myani 125ms;
       -o-animation: myani 125ms;
          animation: myani 125ms;
  -webkit-transition: -webkit-transform 125ms;
  transition: -webkit-transform 125ms;
  -o-transition: -o-transform 125ms;
  -moz-transition: transform 125ms, -moz-transform 125ms;
  transition: transform 125ms;
  transition: transform 125ms, -webkit-transform 125ms, -moz-transform 125ms, -o-transform 125ms;
}
.socialmediaandothers-exit {
  opacity: 1;
}
.socialmediaandothers-exit-active {
  opacity: 0.5;
}
.socialmediaandothers-exit-done {
  opacity: 0;
  height: 0px !important;
}
.socialmediaandothers-enter {
  height: 50%;
  opacity: 0;
}
.socialmediaandothers-enter-active {
  height: 80%;
  opacity: 0.8;
}
.socialmediaandothers-enter-done {
  height: 100%;
  opacity: 1;
}
@-webkit-keyframes myani {
  from {
    z-index: 0;
  }
  to {
    z-index: 1203;
  }
}
@-moz-keyframes myani {
  from {
    z-index: 0;
  }
  to {
    z-index: 1203;
  }
}
@-o-keyframes myani {
  from {
    z-index: 0;
  }
  to {
    z-index: 1203;
  }
}
@keyframes myani {
  from {
    z-index: 0;
  }
  to {
    z-index: 1203;
  }
}

.menuBtnTrans-exit {
  width: 50px !important;
  margin-left: 50%;
  -webkit-transform: rotateY(180deg);
     -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
      -ms-transform-origin: left;
       -o-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  -o-transition: -o-transform 250ms;
  -moz-transition: transform 250ms, -moz-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms, -moz-transform 250ms, -o-transform 250ms;
  z-index: -1 !important;
}
.menuBtnTrans-exit-active {
  opacity: 0;
  margin-left: 45%;
  -webkit-transform: rotateY(90deg);
     -moz-transform: rotateY(90deg);
          transform: rotateY(90deg);
  -webkit-transform-origin: inherit;
     -moz-transform-origin: inherit;
      -ms-transform-origin: inherit;
       -o-transform-origin: inherit;
          transform-origin: inherit;
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  -moz-transition: all 250ms;
  transition: all 250ms;
  z-index: -1 !important;
}
.menuBtnTrans-exit-done {
  width: 50px !important;
  margin-left: 0%;
  -webkit-transform: rotateY(0deg);
     -moz-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
      -ms-transform-origin: left;
       -o-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  -o-transition: -o-transform 250ms;
  -moz-transition: transform 250ms, -moz-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms, -moz-transform 250ms, -o-transform 250ms;
}

.ProgressBarTrans-enter {
  margin-right: 0%;
}
.ProgressBarTrans-enter-active {
  right: 350px !important;
  -webkit-transition: all ease-in-out 400ms;
  -o-transition: all ease-in-out 400ms;
  -moz-transition: all ease-in-out 400ms;
  transition: all ease-in-out 400ms;
}
.ProgressBarTrans-exit {
  margin-right: 350px;
}
.ProgressBarTrans-exit-active {
  margin-right: 0%;
  -webkit-transition: all ease-in-out 400ms;
  -o-transition: all ease-in-out 400ms;
  -moz-transition: all ease-in-out 400ms;
  transition: all ease-in-out 400ms;
}
.ProgressBarTrans-enter-done {
  margin-right: 350px;
}

.alert-enter-done {
  height: 100%;
}
.alert-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
     -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
       -o-transform: scale(0.9);
          transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
     -moz-transform: translateX(0);
      -ms-transform: translateX(0);
       -o-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  -o-transition: opacity 300ms, -o-transform 300ms;
  -moz-transition: opacity 300ms, transform 300ms, -moz-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms, -moz-transform 300ms, -o-transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
     -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
       -o-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, -webkit-transform 300ms;
  -o-transition: opacity 300ms, -o-transform 300ms;
  -moz-transition: opacity 300ms, transform 300ms, -moz-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms, -moz-transform 300ms, -o-transform 300ms;
}

.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
       transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  -moz-transform-origin: 50% -50%;
   -ms-transform-origin: 50% -50%;
    -o-transform-origin: 50% -50%;
       transform-origin: 50% -50%;
}

.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
   -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
       transform-origin: 50% 100%;
}

.imghvr-hinge-down:hover > img {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
       transform: rotateX(90deg);
  opacity: 0;
}

.imghvr-hinge-down:hover figcaption {
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
       transform: rotateX(0);
}

[class*=" imghvr-hinge-"]:hover img,
[class^="imghvr-hinge-"]:hover img {
  opacity: 0;
}

.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

[class*=" imghvr-hinge-"] figcaption,
[class^="imghvr-hinge-"] figcaption {
  opacity: 0;
  z-index: 1;
}

[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #135796;
  padding: 30 px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}

.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  -moz-transform-origin: 50% -50%;
  -ms-transform-origin: 50% -50%;
  -o-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}

[class*=" imghvr-"] figcaption,
[class^="imghvr-"] figcaption {
  background-color: #135796;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}

[class*=" imghvr-"],
[class*=" imghvr-"] *,
[class*=" imghvr-"]:after,
[class*=" imghvr-"] :after,
[class*=" imghvr-"]:before,
[class*=" imghvr-"] :before,
[class^="imghvr-"],
[class^="imghvr-"] *,
[class^="imghvr-"]:after,
[class^="imghvr-"] :after,
[class^="imghvr-"]:before,
[class^="imghvr-"] :before {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

[class*=" imghvr-"],
[class^="imghvr-"] {
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  background-color: #2266a5;
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
       backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
       transform: translateZ(0);
}

[class*=" imghvr-hinge-"]:hover figcaption,
[class^="imghvr-hinge-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
     -moz-transition-delay: 0.21s;
       -o-transition-delay: 0.21s;
          transition-delay: 0.21s;
}

