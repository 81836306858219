@font-face {
    font-family: 'GothicRegular';
    src: url('../Fonts/CenturyGothicRegular.ttf') format('truetype');
   } /* //Subtitle 2 */
   
@font-face {
    font-family: 'AmandaSignature';
    src: url('../Fonts/amandasignature.ttf') format('truetype');
} /* //by font*/

@font-face {
    font-family: 'Brigham';
    src: url('../Fonts/Brigham.otf') format('truetype');
}/* //signeture font */

@font-face {
    font-family: 'CenturyGothicBold';
    src: url('../Fonts/CenturyGothicBold.ttf') format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../Fonts/OpenSans-Semibold.ttf') format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSansBold';
    src: url('../Fonts/OpenSans-Bold.ttf') format('truetype');
}/* //Success Stories */

@font-face {
    font-family: 'OpenSans-Semibold_0';
    src: url('../Fonts/OpenSans-Semibold_0.ttf') format('truetype');
}/* //Success Stories */

